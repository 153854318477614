import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'src/App.css';


//	Main Template Parts
import Footer from 'src/components/parts/Footer';

//	Pages
import Home from 'src/components/pages/Home';
import Tag from 'src/components/pages/Tag';
import Blank from 'src/components/pages/Blank';
import Search from 'src/components/pages/Search';
import Chatroom from 'src/components/pages/Chatroom';
import Error from 'src/components/pages/Error';
import About from 'src/components/pages/About';
import Compliance from 'src/components/pages/Compliance';

class App extends Component {
  render() {
    return (
	<div className="App">
	  <BrowserRouter>
	      <Switch>
	        <Route path="/" component={Home} exact />
			<Route path="/tag/" component={Tag} />
	        <Route path="/about/" component={About} />
	        <Route path="/compliance/" component={Compliance} />
			<Route path="/search/" component={Search} />
			<Route path="/room/" component={Chatroom} />
			<Route path="/public_room/" component={Blank} />
			<Route path="/private_room/" component={Blank} />
			<Route path="/secure/" component={Blank} />
			<Route component={Error} />
	      </Switch>
		<Footer />
	  </BrowserRouter>
	</div>
    );
  }
}

export default App;
