import React, { Component } from 'react';
import { connect } from "react-redux";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {
	  return (
		<footer>
			<div className="first-footerbox">
				<div className="footer-links">
					<a href="/secure/dominatrix">Model Register</a> |
					<a href="/secure/webmasters">Affiliate Program</a> |
					<span onClick={this.loadRegister}>Contact</span>
				</div>
			</div>
			<div className="second-footerbox">
				<h1>More Sites Like Femdom Fetish Cams:</h1>
				<div className="footer-sites">
					<a href="https://www.femdomonline.com/">FemdomOnline</a> |
					<a href="https://badgirlscam.com/">BadGirlsCam</a> |
					<a href="https://latinadominatrixcams.com/">LatinaDominatrixCams</a> |
					<a href="https://www.livefemdomporn.com/">LiveFemdomPorn</a>
				</div>
				<p>NOTICE: This website contains cams, photos and videos of explicit sexual content so you must be of legal age in your country of residence to be able to visit it.</p>
				<p>All the models shown were at least 18 years old at the time of being photographed.</p>
			</div>
			<div className="third-footerbox">
				<a href="/compliance/">18 USC 2257 Record-Keeping Requirements Compliance Statement</a>
				<p>&copy; 2009 - 2020 femdomfetishcams.com. All rights reserved.</p>
			</div>
		</footer>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);