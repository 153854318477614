import React from 'react';
import {Helmet} from 'react-helmet';
import Header from 'src/components/parts/Header';

const Error = () => {
    return (
		<div>
			<Header />
			<div className="container">
				<div className="general">
					<h1>404 Page Not Found</h1>
					<Helmet>
						<title>Page Not Found</title>
						<meta name="robots" content="noindex" />
						<meta name="googlebot" content="noindex" />
					</Helmet>
				</div>
			</div>
		</div>
    );
}

export default Error;
 