import React, { Component } from 'react';
import UserContainer from 'src/components/parts/UserContainer';
import ChatInfo from 'src/components/parts/ChatInfo';
import {Helmet} from 'react-helmet';
import { connect } from "react-redux";
import { getChatroom, setText, setUsers } from "src/cms.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Header from 'src/components/parts/Header';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  meta_title: state.meta_title,
	  meta_description: state.meta_description,
	  content: state.content,
	  currentUser:state.currentUser,
	  top_text: state.top_text
	};
};

//	Main Home Class
class Chatroom extends Component {

	constructor(props) {

		super(props);
		
		var width = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
		var height = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;
		
		//	Adjust for desktop room container
		if( width >= 769 ){
			//var width = document.getElementById('room');
			width -= 10;
		}

		if( width > 1500 ) width = 1500;

		if( height > 620 )	height = width * 0.75;
		else				height = height - 40;

		this.state = {width: width, height: height}

		this.replaceEmptyAge = this.replaceEmptyAge.bind(this);

	}

	//	On Load
	componentDidMount() {

		getChatroom( this.props );

		setText(this.props);

		
	}


	componentDidUpdate(prevProps, prevState) {

		//	Make sure there was a change in pages
		if( this.props.location.pathname !== prevProps.location.pathname) {

			this.props.dispatch({type: 'GET_ALL_USERS_RESET'});	// after getting initial users will allow for when scroll happens will get all users again

			this.props.dispatch({type: 'RESET_SHOW_USERS'});
			
			getChatroom(prevProps)

			setUsers(prevProps)

			setText(prevProps)

		} else {

			window.scrollTo({ top:0 });

		}
		
	}


	replaceEmptyAge = () => {
		return Math.floor(Math.random() * (30 - 19)) + 19;
	}


	render() {

	  return (
	  	<div>
			<Header />
			<div className="container">
				<div id="room">
					<div className="room-centered">
						<div className="iframe-container">
							<iframe src={this.props.currentUser.iframe} height={this.state.height} width={this.state.width} title={this.props.currentUser.username} />
						</div>
						<div className="room-content">
							<div className="room-content-top">
								<h1 className="room-info-left">{this.props.currentUser.name}</h1>
								<div className="room-info-right">
									<div className="live-box"><FontAwesomeIcon icon={faCircle} className="blink" /><span>LIVE CHAT</span></div>
									<ChatInfo />
								</div>
							</div>
							<div className="room-content-middle">
								<div className="welcome-message">
									<h1>Welcome inside the live dungeon of mistress <span className="room-name">{this.props.currentUser.name}</span> (free acces). If you want a real femdom chat experience then go private right now!</h1>
								</div>
								<div className="user-actions">
									<form id="chat-form">
										<input type="text" name="Message Model" className="chat-input" autoComplete="off" placeholder="Tap here to chat" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Tap here to chat"} />
										<a href={this.props.currentUser.live_room_url} className="chat-send" title="Send Message">
											<FontAwesomeIcon icon={faArrowRight} />
										</a>
									</form>
								</div>
							</div>
							<div className="room-content-bottom">
								<a className="room-btn private" href={this.props.currentUser.live_room_url} title="Enter Private Chat" alt="Enter Private Button">Enter Private</a>
								<a className="room-btn spyshow" href={this.props.currentUser.live_room_url} title="Enter Spy Show Now" alt="Spy Show Button">Spy Show</a>
							</div>
						</div>
					</div>
					<h1 className="browse-more">Check Out More Webcams <FontAwesomeIcon icon={faArrowDown} /></h1>
					<UserContainer />
					<div className="chatroom-bottom-space"></div>
					<Helmet>
						<title>{this.props.meta_title}</title>
						<meta name="description" content={this.props.meta_description} />
					</Helmet>
				</div>
			</div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Chatroom);


