import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	toggle menu
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });

	}

	// mobile nav
	renderMobileMenuOpened = () => {

		return (
			<section id="main-menu">
				<div className="menu-overlay" onClick={this.handleMenuButton}></div>
				<nav>
					<div className="nav-top-content">
						<h1 className="top-content-text">Categories</h1>
						<div className="top-content-closebtn" onClick={this.handleMenuButton}><FontAwesomeIcon icon={faTimes} className="close-icon" /></div>
					</div>
					<div className="nav-main-content">
						<div className="nav-main-box">
							<ul className="categ-list">
								<li className="categ-title">AGE</li>
								<li className="categ-item"><NavLink to="/tag/18-to-19/" activeClassName="categ-active" onClick={this.handleMenuButton}>18 to 19</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/20-to-25/" activeClassName="categ-active" onClick={this.handleMenuButton}>20 to 25</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/25-to-40/" activeClassName="categ-active" onClick={this.handleMenuButton}>25 to 40</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/mature/" activeClassName="categ-active" onClick={this.handleMenuButton}>Mature</NavLink></li>
							</ul>
						</div>
						<div className="nav-main-box">
							<ul className="categ-list">
								<li className="categ-title">ETHNICITY</li>
								<li className="categ-item"><NavLink to="/tag/asian/" activeClassName="categ-active" onClick={this.handleMenuButton}>Asian</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/ebony/" activeClassName="categ-active" onClick={this.handleMenuButton}>Ebony</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/latina/" activeClassName="categ-active" onClick={this.handleMenuButton}>Latina</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/white/" activeClassName="categ-active" onClick={this.handleMenuButton}>White</NavLink></li>
							</ul>
						</div>
						<div className="nav-main-box">
							<ul className="categ-list">
								<li className="categ-title">BUILD</li>
								<li className="categ-item"><NavLink to="/tag/petite/" activeClassName="categ-active" onClick={this.handleMenuButton}>Petite</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/average/" activeClassName="categ-active" onClick={this.handleMenuButton}>Average</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/athletic/" activeClassName="categ-active" onClick={this.handleMenuButton}>Athletic</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/bbw/" activeClassName="categ-active" onClick={this.handleMenuButton}>BBW</NavLink></li>
							</ul>
						</div>
						<div className="nav-main-box">
							<ul className="categ-list">
								<li className="categ-title">TAGS</li>
								<li className="categ-item"><NavLink to="/tag/blonde/" activeClassName="categ-active" onClick={this.handleMenuButton}>Blonde</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/brunette/" activeClassName="categ-active" onClick={this.handleMenuButton}>Brunette</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/feet/" activeClassName="categ-active" onClick={this.handleMenuButton}>Feet</NavLink></li>
								<li className="categ-item"><NavLink to="/tag/smoking/" activeClassName="categ-active" onClick={this.handleMenuButton}>Smoking</NavLink></li>
							</ul>
						</div>
					</div>
				</nav>
				<div className="nav-bottom-content">
					<div className="menu-copyright">All models appearing on this site are 18 years of age or older.</div>
				</div>
			</section>
		)
	}


	render() {

		return (
			// menu button rendered by default, then the condition for opening mobile nav
			<div className="header-actionbox">
				<div className="action-btn" onClick={this.handleMenuButton}>
					<FontAwesomeIcon icon={faBars} />
				</div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : null }
			</div>
		);
	}
}

export default MobileMenu;