//
//	Order user part
//
import React, { Component } from 'react';
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {
		users: state.users,
		usersVisible: state.usersVisible,
		usersTotal: state.usersTotal,
		showItems: state.showItems,
		userOrder: state.userOrder,
		top_text: state.top_text,
	};
};

class UserSort extends Component {

	constructor(props) {

		super(props);

	}


	//	Order users by specific..
	orderUsers = (event) => {

		this.props.dispatch({type: 'ORDER_USERS', order:event.target.options[event.target.selectedIndex].dataset.sort});
		//this.props.dispatch({type: 'ORDER_USERS', order:event.target.attributes.getNamedItem('data-sort').value});
		
	};

	
	render() {

		return (
			<select className="cams-filter" onChange={this.orderUsers}>
				<option className="camfilter">FILTERS</option>
				<option className="camfilter" id={this.props.userOrder === 'ATOZ' ? 'active' : ''} data-sort="ATOZ" >ALPHABETICAL A - Z</option>
				<option className="camfilter" id={this.props.userOrder === 'ZTOA' ? 'active' : ''} data-sort="ZTOA">ALPHABETICAL Z - A</option>
				<option className="camfilter" id={this.props.userOrder === 'POPULAR' ? 'active' : ''} data-sort="POPULAR">MOST POPULAR</option>
				<option className="camfilter" id={this.props.userOrder === 'OLDEST' ? 'active' : ''} data-sort="OLDEST">AGE OLDEST</option>
				<option className="camfilter" id={this.props.userOrder === 'YOUNGEST' ? 'active' : ''} data-sort="YOUNGEST">AGE YOUNGEST</option>
			</select>
		);
	}

}

export default connect(mapStateToProps)(UserSort);