import React from 'react';
import {Helmet} from 'react-helmet';
import Header from 'src/components/parts/Header';

const Compliance = () => {
    return (
		<div>
			<Header />
			<div className="container">
				<div className="general">
					<h1>18 USC 2257 Record-Keeping Requirements Compliance Statement</h1>
					<p>All persons who appear in any visual depiction contained in this service were 18 years or older at the time of the creation of such depiction. Except with respect to visual depictions of actual sexually explicit conduct made prior to July 3, 1995 which are exempt from 18 U.S.C. Sec. 2257 and 28 CFR 75</p>
					<Helmet>
						<title>18 USC 2257 Record-Keeping Requirements Compliance Statement</title>
						<meta name="robots" content="noindex" />
						<meta name="googlebot" content="noindex" />
					</Helmet>
				</div>
			</div>
		</div>
    );
}

export default Compliance;