import React from 'react';
import { connect } from "react-redux";
import { setText } from "src/cms.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Img from 'react-image';


const mapStateToProps = state  => {
	return {
	  currentUser:state.currentUser
	};
};


class ChatInfo extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpenInfo: false }
        this.handleInfoButton = this.handleInfoButton.bind(this);
        this.generateRandomAge = this.generateRandomAge.bind(this);

    }
    
    // On Load
	componentDidMount() {
		setText(this.props);
	}

	//	display model info
	handleInfoButton(event) {
		this.setState({ isOpenInfo: !this.state.isOpenInfo });

    }
    
    generateRandomAge = () => {
		return Math.floor(Math.random() * (30 - 19)) + 19;
    }
    
    renderProfileImage = () => {

		// Check if it is empty.
		if( typeof this.props.currentUser.picture_url === 'undefined' ) {
			return '';
		}

		// Image is there return.
		return(
			<Img src={document.location.protocol+'//'+document.domain+this.props.currentUser.picture_url} className="model-thumb" />
		);
		
	}

	// model info in chatroom
	renderInfoOpened = () => {

		return (
			<section id="info-popup">
                <div className="popup">
                    <div className="info-popup-top">
                        <div className="info-popup-top-left">
                            <div><label>Age:</label><span>{this.props.currentUser.age === 0 ? this.generateRandomAge() : this.props.currentUser.age} years old</span></div>
                            <div><label>Ethnicity:</label><span>{this.props.currentUser.ethnicity === 'none' ? 'Ask' : this.props.currentUser.ethnicity}</span></div>
                            <div><label>Body Type:</label><span>{this.props.currentUser.body_type === 'none' ? 'Average' : this.props.currentUser.body_type}</span></div>
                            <div><label>Live Chatters:</label><span>{this.props.currentUser.chatters === 0 ? Math.floor(Math.random() * 100) : this.props.currentUser.chatters}</span></div>
                            <div><label>Country Code:</label><span>{this.props.currentUser.country_code === '' ? 'US' : this.props.currentUser.country_code}</span></div>
                            <div><label>I Speak:</label><span>{this.props.currentUser.languages === '' ? 'English' : this.props.currentUser.languages}</span></div>
                        </div>
                        <div className="info-popup-top-right">
                            <a className="model-thumb-link" href={this.props.currentUser.live_room_url}>
                                {this.renderProfileImage()}
                            </a>
                        </div>
                    </div>
                    <div className="info-popup-bottom">
						<label>About Me:</label>
                        <span>{this.props.currentUser.bio === '' ? 'I love to get nasty in private shows. Join me now!' : this.props.currentUser.bio}</span>
                    </div>
                </div>
			</section>
		)
	}


	render() {

		return (
			// info button rendered by default, then the condition to display model info
            <div className="profile-box" onClick={this.handleInfoButton} title="View Profile Info">
                {!this.state.isOpenInfo ? <FontAwesomeIcon icon={faUserPlus} onClick={this.handleInfoButton} /> : <FontAwesomeIcon icon={faTimes} onClick={this.handleInfoButton} /> }
                {this.state.isOpenInfo ?  this.renderInfoOpened() : null }
            </div>
		);
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(ChatInfo);