import React from 'react';
import { api_url } from "src/cms.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

//
//	Smart search teen keyword ( search for 18 -19)
//
class SearchBox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {Search: '', searchSuggestion:[]}		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	//
	//	Update input text change
	//
	handleChange(event) {
		
		this.setState({ [event.target.name]: event.target.value});

		console.log( event.target.value );

		if( event.target.value.length >= 4 ) {

			fetch( api_url + '/web-api/get-search-matches/?s='+event.target.value)
			.then( results => { return results.json() })
			.then( data => {
				
				document.getElementById('results_suggest').style.width = document.getElementById('search-input').offsetWidth + 'px';

				if( data.length > 0 ) {

					this.setState({searchSuggestion: data });
					
					document.getElementById("results_suggest").style.display = "block";

				} else {

					document.getElementById("results_suggest").style.display = "none";

				}
				
			})
		}
	}


	//	Submit form
	handleSubmit(event) {

		event.preventDefault();
		
		window.location="/search/"+this.state.Search.replace(/ /g, '-')+'/';
		
	}

	// Have to set as html or else the <span> shows as text instead of html tag.
	highlightMatch(text) {

		return{ __html: text.replace( this.state.Search, "<span>"+this.state.Search+"</span>" )}
		
	}

	render() {
		return (
			<div className="app-search">
				<form id="search-form" onSubmit={this.handleSubmit}>
					<input type="text" name="Search" className="search-input" id="search-input" autoComplete="off" placeholder="SEARCH ..." onChange={this.handleChange} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "SEARCH ..."} />
					<button id="search-btn" title="Search">
						<FontAwesomeIcon icon={faSearch} />
					</button>
				</form>
				<ul id="results_suggest">
				{this.state.searchSuggestion.map( (text) =>
					<li key={text}>
						<a href={'/search/'+text.replace(' ', '-')+'/'} dangerouslySetInnerHTML={this.highlightMatch(text)}></a>
					</li>
				)}
				</ul>
			</div>
		);
	}
}


export default SearchBox;
