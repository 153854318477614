//
//	Handle rendering of header, basicly for switches between mobile and desktop
//
import React from 'react';
import Img from 'react-image';
import logo from 'public/static/img/logo.png';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import SearchBox from 'src/components/parts/SearchBox';
import UserSort from 'src/components/parts/UserSort';
import Register from 'src/components/parts/Register';
import MobileMenu from 'src/components/parts/MobileMenu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faCircle, faTag } from '@fortawesome/free-solid-svg-icons';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
		top_text: state.top_text,
		usersTotal: state.usersTotal
	};
};

class Header extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	renderMobileHeader = () => {
		return(
			<header id="header">
				<div className="header-main">
					<MobileMenu />
					<div className="app-logo">
						<a href="/" title="Femdom Fetish Cams">
							<Img className="mylogo" src={logo} alt="Femdom Fetish Cams" />
						</a>
					</div>
					<div className="header-actionbox">
						<div className="action-btn">
							<div className="login-mobile-btn" onClick={this.loadRegister}><FontAwesomeIcon icon={faUserCircle} /></div>
						</div>
					</div>
				</div>
				<div className="header-secondary">
					<div className="flex-left"><UserSort /></div>
					<div className="flex-right"><SearchBox /></div>
				</div>
				<Register />
			</header>
		)
	}

	renderDesktopHeader = () => {
		return(
			<header className="header-xl">
				<div className="header-xl-top">
					<div className="header-xl-part-left">
						<div className="header-xl-part-left-logo">
							<a href="/" title="FemdomFetishCams"><Img className="mylogo-xl" src={logo} alt="Femdom Fetish Cams" /></a>
						</div>
						<div className="header-xl-part-left-modelslive">
							<span>{this.props.usersTotal} webcams online</span>
							<FontAwesomeIcon icon={faCircle} className="blink" />
						</div>
					</div>
					<div className="header-xl-part-right">
						<SearchBox />
					</div>
				</div>
				<div className="header-xl-bottom">
					<ul className="nav-list">
						<li className="nav-item"><NavLink to="/" className="nav-link" activeClassName="link-active" exact>HOME</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/18-to-19/" className="nav-link" activeClassName="link-active">18-19</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/20-to-25/" className="nav-link" activeClassName="link-active">20-25</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/25-to-40/" className="nav-link" activeClassName="link-active">25-40</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/mature/" className="nav-link" activeClassName="link-active">MATURE</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/asian/" className="nav-link" activeClassName="link-active">ASIAN</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/ebony/" className="nav-link" activeClassName="link-active">EBONY</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/latina/" className="nav-link" activeClassName="link-active">LATINA</NavLink></li>
						<li className="nav-item"><NavLink to="/tag/white/" className="nav-link" activeClassName="link-active">WHITE</NavLink></li>
						<li className="nav-btn-login"><div title="Login" onClick={this.loadRegister}>Login<FontAwesomeIcon icon={faUserCircle} className="circle-icon-desktop" /></div></li>
						<li className="nav-btn-account"><div title="Create Free Account" onClick={this.loadRegister}>JOIN FREE</div></li>
					</ul>
				</div>
				<div className="header-tags">
					<NavLink to="/tag/blonde/" className="tag-link" activeClassName="tag-active"><FontAwesomeIcon icon={faTag} />blonde</NavLink>
					<NavLink to="/tag/brunette/" className="tag-link" activeClassName="tag-active"><FontAwesomeIcon icon={faTag} />brunette</NavLink>
					<NavLink to="/tag/feet/" className="tag-link" activeClassName="tag-active"><FontAwesomeIcon icon={faTag} />feet</NavLink>
					<NavLink to="/tag/smoking/" className="tag-link" activeClassName="tag-active"><FontAwesomeIcon icon={faTag} />smoking</NavLink>
					<div className="user-balance-info">My Balance: <span>0 credits</span></div>
				</div>
				<Register />
			</header>
		)
	}

	
	render() {

		// At width 768 or greater we will display the desktop header ( default should always be for mobile )
		if(window.innerWidth > 768 ) {

			return(
				this.renderDesktopHeader()
			);

		} else {

			return (
				this.renderMobileHeader()
			);

		}
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Header);